import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import "../css/typography.css"
import GoldenVisa from "../images/golden/GoldenVisa.webp"
import LastSectionPng from "../images/golden/LastSection.webp"
import TitleBackground from "../images/home/TitleBackground.webp"
import TitleBackMov from "../images/home/TitleBackMov.webp"
import CTA from "../components/buttons/cta"
import { Helmet } from 'react-helmet'

const FirstSection = styled.div`
    background-image: url(${GoldenVisa});
    background-size: cover;
    margin: 0;
    color: white;
    padding: 5% 0 5% 0;
    min-height: 30vh;
    display: flex;
    align-items: center;

    >div:nth-child(1) {
        width: 900px;
        background-image: url(${TitleBackground});
        background-size: cover;
        font-size: 15.5px;
        font-family: 'M Extra Bold';
        padding: .1% 0 .1% 3%;
    }

    @media only screen and (min-width: 1800px) {
        >div:nth-child(1) {
            width: 1550px;
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 2500px) {
        >div:nth-child(1) {
            width: 2150px;
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 3500px) {
        >div:nth-child(1) {
            width: 2600px;
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px) {
        >div:nth-child(1) {
            width: 3100px;
            font-size: 58px;
        }
    }
    @media only screen and (max-width: 885px) {
        >div:nth-child(1) {
            width: 750px;
            font-size: 14.5px;
            padding: .1% 0 .1% 3%;
        }
    }
    @media only screen and (max-width: 736px) {
        >div:nth-child(1) {
            width: 580px;
            font-size: 11px;
            padding: .1% 0 .1% 3%;
        }
    }
    @media only screen and (max-width: 570px) {
        width: 100vw;

        >div:nth-child(1) {
            width: 99%;
            font-size: 3vw;
            padding: .1% 0 .1% 3%;
            background-image: url(${TitleBackMov});
            background-position-x: 100%;
        }
        >div:nth-child(1) h1 {
            width: 91%;
            font-size: 5vw;
        }
    }
`

const SecondSection = styled.div`
    font-size: 17px;
    font-family: 'M Medium';
    padding: 3% 5% 5% 5%;

    h3 {
        font-family: 'M Bold';
        font-size: 20px;
        margin-top: 40px;
    }
    li {
        margin-bottom: 20px;
    }
    >p:nth-child(4) {
        margin-top: 40px;
    }

    @media only screen and (min-width: 1800px)  {
        font-size: 1.2vw;
        h3 {
            font-size: 2vw;
        }
    }
    @media only screen and (min-width: 3500px)  {
        font-size: 1.5vw;
        h3 {
            font-size: 2.7vw;
        }
    }
    @media only screen and (max-width: 540px)  {
        font-size: 4.5vw;

        h3 {
            font-size: 5vw;
        }
    }
`

const LastSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3%;
    background-image: url(${LastSectionPng});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h2 {
        margin-top: 13%;
        font-family: 'M Regular';
        font-size: 27px;
        width: 350px;
        color: white;
        text-align: center;
    }
    span {
        font-family: 'M Extra Bold';
        text-align: center;
    }
    >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4%;
    }
    p {
        font-family: 'M Medium';
        font-size: 17px;
        color: white;
        margin-bottom: 3%;
    }
    >div:nth-child(2) {
        padding: 0 6% 0 6%;
        margin-bottom: 2%;
    }
    @media only screen and (min-width: 1800px)  {
        p{ 
            font-size:25px;
        }
        h2 {
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 2500px)  {
        p{ 
            font-size: 29px;
        }
        h2 {
            font-size: 60px;
            width:600px;
        }     
    }    
    @media only screen and (min-width: 3500px)  {
        h2 {
            font-size: 68px;
        }
        p{ 
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px)  {
        h2 {
            font-size: 82px;
        }
        p{ 
            font-size: 60px;
        }
    }


    @media only screen and (max-width: 540px)  {
        p {
            font-size: 4.5vw;
            margin-bottom: 10%;
        }
    }
`

const SeparatorLight = styled.div`
    width: 50px;
    border-bottom: 3px solid white;
    border-radius: 50px;
    @media only screen and (min-width: 1200px) {
        
        width: 80px;  
    }
    @media only screen and (min-width: 1800px) {
        
        width: 120px;
    }
    @media only screen and (min-width: 2500px) {
        
        width: 200px;
    }
    @media only screen and (min-width: 3500px) {
        
        width: 250px;
    }
    @media only screen and (max-width: 750px) {
        margin-bottom: 10%;
    }
` 

const ContactoDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
`


const IndexPage = () => {
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
            <Helmet>
                <title>Invierte en España y obtén la nacionalidad española</title>
                <meta name="description" content="Obtén la Golden Visa para ti y tu familia invirtiendo en una propiedad en España."/>
            </Helmet>
            <FirstSection title="Adquiere un bien inmueble en España y obtén la residencia española.">
                <div>
                    <h1>GOLDEN VISA Y NACIONALIDAD ESPAÑOLA</h1>
                </div>
            </FirstSection>
            <SecondSection>
                <p>En el año 2013, España emitió una ley que permite a No Residentes Europeos y a sus familias, obtener un permiso de residencia en el país, al realizar una inversión de €500 mil euros en bienes raíces.</p>
                <h3>La Golden Visa:</h3>
                <ul>
                    <li>Requiere una inversión en uno o más bienes inmuebles, de cualquier tipo, con valor total de €500 mil euros o superior. Debe ser líquida, sin préstamos o hipotecas.</li>
                    <li>Aplica al inversionista, su cónyuge y sus hijos menores de 18 años. También aplica para padres e hijos que puedan demostrar dependencia económica, incluidos jóvenes universitarios que aún vivan en casa del inversionista.</li>
                    <li>Requiere la comprobación del sustento para el inversionista y su familia.</li>
                    <li>Se obtiene tan solo unas semanas después de realizada la inversión.</li>
                    <li>Se otorga por tres años y es renovable por 5 años más. Posteriormente se puede aplicar al visado de larga duración.</li>
                    <li>Otorga a los residentes libre tránsito en los 29 países del Área Schengen.</li>
                    <li>Permite al inversionista trabajar en España como empleado o por cuenta propia.</li>
                </ul>
                <p>Durante el tiempo de duración de la residencia, es necesario conservar la inversión, pagar el impuesto predial anual, contar con un seguro español de gastos médicos y acreditar medios económicos para demostrar capacidad de sustento en cada renovación. Esto ya no será necesario una vez obtenida la nacionalidad.</p>
            </SecondSection>
            <ContactoDiv>
                <CTA title='CONTÁCTANOS' />
            </ContactoDiv>
            <LastSection title="Obtén la nacionalidad española al adquirir un inmueble en España.">
                <div>
                    <h2>NACIONALIDAD <span>ESPAÑOLA</span></h2>
                    <SeparatorLight />
                </div>
                <div>
                    <p>Una vez cumplidos dos años del permiso de residencia, los ciudadanos de cualquier país de Iberoamérica pueden optar por la nacionalidad española. Es importante tomar en cuenta que durante los dos años inmediatos anteriores a iniciar este trámite, es necesario residir en España con ausencias no mayores a tres meses.</p>
                    <p>La nacionalidad otorga todos los derechos de un ciudadano español, además de los de un ciudadano de la Unión Europea, incluidos los servicios de salud, el libre tránsito, así como el derecho a estudiar y trabajar en cualquier país de la UE.</p>
                    <p> Una vez obtenida la nacionalidad, ya no es necesario conservar la inversión inicial ni comprobar ingresos. Además no es necesario visitar ni residir en España.</p>
                </div>
            </LastSection>
        </Layout>
    ): (null)}

export default IndexPage
